import { IDanhMucToaXeResponseBase } from '@/core/interfaces/ApiResponses';

const useDanhMucToaXe = () => {
  const isValidLabel = (item: IDanhMucToaXeResponseBase) => {
    if (item.is_valid === 0) return 'Chờ phê duyệt';

    return 'Hợp lệ';
  }

  const isTextDangerColumn = ({ row }: { row: IDanhMucToaXeResponseBase }) => row.is_valid === 0 ? 'text-danger' : '';

  return {
    isTextDangerColumn,
    isValidLabel,
  };
}

export default useDanhMucToaXe;
