
import { debounce } from 'lodash';
import {defineComponent, ref} from 'vue';
import {IDanhMucToaXeResponseBase} from '@/core/interfaces/ApiResponses';
import {setCurrentPageTitle} from '@/core/helpers/breadcrumb';
import {usePagination} from '@/core/hooks/use-pagination';
import {swalNotification} from '@/core/helpers/utils';
import {useRouterCustom} from '@/core/hooks/use-router-custom';
import { useButtonCustom } from '@/core/hooks/use-button-custom';

import DanhMucToaXeService from '@/core/services/DanhMucToaXe.service';
import DanhMucToaXeTable from '@/views/crafted/pages/danh-muc-toa-xe/danh-muc-toa-xe-table/DanhMucToaXeTable.vue';
import PerPageSelection from '@/components/shared/per-page-selection/PerPageSelection.vue';
import Pagination from '@/components/shared/pagination/Pagination.vue';
import ButtonCustom from '@/components/shared/buttons/ButtonCustom.vue';
import PageRowLayout from '../../../../layout/_shared/PageRowLayout.vue';

export default defineComponent({
	name: 'danh-muc-toa-xe',

	components: { DanhMucToaXeTable, PerPageSelection, Pagination, ButtonCustom, PageRowLayout },

	setup() {
		const danhMucToaXeItems = ref<IDanhMucToaXeResponseBase[]>([]);
		const { push } = useRouterCustom();
		const { currentPage, totalItems, perPage, perPageArr, loading, searchTerm } = usePagination();
		const { ButtonsType, ButtonTypeColors } = useButtonCustom();

		return {
			danhMucToaXeItems,
      currentPage,
      totalItems,
      perPage,
      perPageArr,
      loading,
      push,
      searchTerm,
      ButtonsType,
      ButtonTypeColors,
		}
	},
	async mounted() {
		setCurrentPageTitle("Danh mục toa xe");
		await this.fetchData();
	},

	watch: {
		async currentPage() {
			await this.fetchData();
		},

		async perPage() {
			await this.fetchData();
		}
	},

	methods: {
		async fetchData() {
			try {
				this.loading = true;
				const {data: {data: {data, total}}} = await DanhMucToaXeService.list(
					this.currentPage,
					this.perPage,
          this.searchTerm,
				);
				this.danhMucToaXeItems = data;
				this.totalItems = total;
				this.loading = false;
			} catch {
				await swalNotification(
					'Có lỗi xảy ra khi lấy danh sách danh mục toa xe',
					'error',
				);
				this.loading = false;
			}
		},
    searchDMTX: debounce(function () {
      this.fetchData();
    }, 500)
	}
})
