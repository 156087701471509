
import {defineComponent, PropType} from 'vue';
import { useButtonCustom } from '@/core/hooks/use-button-custom';
import {IDanhMucToaXeResponseBase} from '@/core/interfaces/ApiResponses';
import {useRouterCustom} from '@/core/hooks/use-router-custom';
import {swalErrNotification, swalNotification} from '@/core/helpers/utils';

import ButtonCustom from '@/components/shared/buttons/ButtonCustom.vue';
import DanhMucToaXeService from '@/core/services/DanhMucToaXe.service';
import ConfirmationDialog from '@/components/confirmation-dialog/ConfirmationDialog.vue';
import useDanhMucToaXe from '@/core/hooks/use-danh-muc-toa-xe';

export default defineComponent({
	name: 'danh-muc-toa-xe-table',

	components: { ButtonCustom, ConfirmationDialog },

	emits: ['delete-danh-muc-toa-xe-success'],

	setup() {
		const {push} = useRouterCustom();

		const { ButtonsType, ButtonTypeColors } = useButtonCustom();

    const { isValidLabel, isTextDangerColumn } = useDanhMucToaXe();

		return {
			push,
			ButtonsType,
      ButtonTypeColors,
      isTextDangerColumn,
      isValidLabel,
		};
	},

	props: {
		items: {
			type: Array as PropType<Array<IDanhMucToaXeResponseBase>>,
		},
		isLoading: {
			type: Boolean,
			default: false,
		},
	},

	data: () => ({
		isDeletingModalDisplay: false,
		deletingDanhMucToaXe: null as IDanhMucToaXeResponseBase | null,
	}),

	methods: {
		onDeleteButtonClicked(id: number) {
			this.isDeletingModalDisplay = true;
			const deletingItem = this.items?.find(item => item.id === id);
			if (deletingItem) {
				this.deletingDanhMucToaXe = deletingItem;
			}
		},

		async confirmDelete() {
			this.isDeletingModalDisplay = false;
			try {
				if (this.deletingDanhMucToaXe) {
					await DanhMucToaXeService.delete(this.deletingDanhMucToaXe?.id);
				}
				await swalNotification(
					'Xóa thành công',
					'success'
				);
				this.$emit('delete-danh-muc-toa-xe-success');
			} catch (error) {
				await swalErrNotification(error, 'Có lỗi xảy ra, không thể xóa D/mục toa xe này');
			}
		}
	}
})
